<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'SendMailHistory',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'SendMailHistory',
      tableCaption: 'Логи отправки писем',
      breadcrumbs: [
        {
          text: 'Телемаркетинг',
          route: { name: 'TelemarketingMain' },
        },
        {
          text: 'Логи отправки писем',
        },
      ],
      tableHeaders: [
        { text: 'Момент отправки', alias: 'moment', order: 'moment' },
        { text: 'Адрес', alias: 'mail', order: 'mail' },
        { text: 'Тема', alias: 'subject', order: 'subject' },
        {
          text: 'Описание или результат',
          alias: 'description',
          order: 'description',
        },
      ],
    };
  },
};
</script>
